import React, { Component } from 'react';
import styled from 'styled-components';

import Button from './components/Button';
import CustomHeader from './components/CustomHeader';
import RadioGroup from './components/RadioGroup';

const Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Form = styled.div`
  width: 90vw;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-top: 40px;
  button {
    margin-top: 20px;
  }
`;

const Input = styled.input`
  width: 85%;
  height: 50px;
  background-color: #efefef;
  border: 2px solid #dcdcdc;
  border-radius: 15px;
  margin: 5px 0;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  font-weight: 500;
  color: #222222;
  padding-left: 25px;
`;

const ErrorMessage = styled.p`
  color: #c8252c;
  margin: 20px 0 0 0;
  font-size: 14px;
  font-weight: 600;
`;

const Checkbox = styled.div`
  max-width: 300px;
  height: 50px;
  font-weight: 600;
  font-size: 14px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  span {
    content: "";
    background-color: ${props => props.selected ? '#c8252c' : '#efefef'};
    width: 25px;
    height: 20px;
    border-radius: 5px;
    border: 2px solid #dcdcdc;
    margin-right: 8px;
  }
`;

class SignupScreen extends Component {
  state = {
    mail: '',
    password: '',
    rePassword: '',
    error: false,
    info: '1 de 3',
    step: 1,
    nombre: '',
    apellidos: '',
    celular: '',
    edad: '',
    sexo: 0,
    options: [
      {
        value: 0,
        text: 'Hombre',
      },
      {
        value: 1,
        text: 'Mujer',
      },
      {
        value: 2,
        text: 'Omitir',
      },
    ],
    terms: false, // terminos y condiciones del servicio
  };

  validateMail = () => {
    return true;
  }

  validateCel = () => {
    return true;
  }

  validateOne = () => {
    const {mail, password, rePassword} = this.state;
    if (password.length > 0 && rePassword.length > 0 && password === rePassword) {
      if (this.validateMail(mail)) {
        console.log('+1');
        this.setState({ error: false, step: 2, info: '2 de 3' });
      }
      else {
        this.setState({ error: '*Introduce un correo válido.'});
      }
    }
    else {
      this.setState({ error: '*Tu contraseña no coincide.'})
    }
  };

  validateTwo = () => {
    const {nombre, apellidos, celular} = this.state;
    if (nombre.length > 0 && apellidos.length > 0 && celular) {
      if (this.validateCel(celular)) {
        this.setState({ error: false, step: 3, info: '3 de 3' });
      }
      else {
        this.setState({ error: '*El número de celular debe ser de 10 dígitos.'})
      }
    }
  };

  validateThree = () => {
    return true;
  };

  back = () => {
    const { step } = this.state;
    const { history } = this.props;
    switch (step) {
      case 1:
        // Return to previous screen
        history.goBack();
        break;
      case 2:
        this.setState({ step: 1, info: '1 de 3', error: false });
        break;
      case 3:
        this.setState({ step: 2, info: '2 de 3', error: false });
        break;
      default:
        break;
    };
  };

  setSex = value => this.setState({ sexo: value });

  handleChange = name => event => this.setState({ [name]: event.target.value });

  toggleTerms = () => {
    const { terms } = this.state;
    this.setState({ terms: !terms });
  };

  render = () => {
    const {
      mail,
      password,
      rePassword,
      error,
      info,
      step,
      nombre,
      apellidos,
      celular,
      edad,
      sexo,
      options,
      terms,
    } = this.state;
    return (
      <Container>
        <CustomHeader
          title="Registro"
          info={info}
          back={this.back}
        />
        {step === 1 && (
          <Form>
            <Input
              type="email"
              placeholder="Correo electrónico"
              value={mail}
              onChange={this.handleChange('mail')}
            />
            <Input
              type="password"
              placeholder="Contraseña"
              value={password}
              onChange={this.handleChange('password')}
            />
            <Input
              type="password"
              placeholder="Confirmar contraseña"
              value={rePassword}
              onChange={this.handleChange('rePassword')}
            />
            {error && (
              <ErrorMessage>
                {error}
              </ErrorMessage>
            )}
            <Button
              bgColor="#c8252c"
              bgHoverColor="#e15e64"
              color="#ffffff"
              onClick={this.validateOne}
            >Siguiente</Button>
          </Form>
        )}
        {step === 2 && (
          <Form>
            <Input
              type="text"
              placeholder="Nombre"
              value={nombre}
              onChange={this.handleChange('nombre')}
            />
            <Input
              type="text"
              placeholder="Apellidos"
              value={apellidos}
              onChange={this.handleChange('apellidos')}
            />
            <Input
              type="number"
              placeholder="Celular"
              value={celular}
              onChange={this.handleChange('celular')}
            />
            {error && (
              <ErrorMessage>
                {error}
              </ErrorMessage>
            )}
            <ErrorMessage>
              *Todos los campos son obligatorios
            </ErrorMessage>
            <Button
              bgColor="#c8252c"
              bgHoverColor="#e15e64"
              color="#ffffff"
              onClick={this.validateTwo}
            >Siguiente</Button>
          </Form>
        )}
        {step === 3 && (
          <Form>
            <Input
              type="number"
              placeholder="Edad"
              value={edad}
              onChange={this.handleChange('edad')}
            />
            <RadioGroup value={sexo} options={options} setValue={this.setSex} />
            <Checkbox selected={terms} onClick={this.toggleTerms}>
              <span />
              Acepto y he leído los términos y condiciones del servicio.
            </Checkbox>
            <ErrorMessage>
              *Todos los campos son obligatorios
            </ErrorMessage>
            <Button
              bgColor="#c8252c"
              bgHoverColor="#e15e64"
              color="#ffffff"
              onClick={this.validateThree}
            >Continuar</Button>
          </Form>
        )}
      </Container>
    );
  };

}

export default SignupScreen;