import React from 'react';
import styled from 'styled-components';

import Logo from '../logo.svg';

const Header = styled.div`
  height: 10vh;
  width: 100%;
  background-color: #092644;
  background-image: linear-gradient(#092644, #0D3569);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const Img = styled.img`
  width: 25%;
  max-width: 90px;
`;

const HeaderLogo = () => (
  <Header>
    <Img src={Logo} alt="Logo de Cheerz"/>
  </Header>
);

export default HeaderLogo;