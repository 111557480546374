/*global globalThis:true*/

import React, { Component } from 'react';
import styled from 'styled-components';

import MainHeader from './components/MainHeader';
import SearchBar from './components/SearchBar';

import Cart from './cart.svg';
import Location from './location.svg';


const Container = styled.div`
  display: flex;
  width: 100vw;
  flex-direction: column;
  align-items: center;
`;

const FixedHeader = styled.div`
  position: fixed;
  top: 0;
  width: 100%;
  background-color: #ffffff;
  background-color: rgba(255,255,255,0.95);

  .base:before {
    position: absolute;
    top: -64px;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100px;
    z-index: -1;
    background-color: #0D3569;
    content: "";
    transition: transform 0.75s ease;
  }
  .top:before {    
    transform: translate(0, 64px);
  }
  .normal:before {
    // transform: translate(0, -64px);
  }
  .hide {
    transform: translate(0, -41px);
    margin-bottom: -20px;
  }
`;

const Filler = styled.div`
  height: 196px;
`;

const Spacer = styled.div`
  width: 90vw;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: baseline;
`;

const SearchStyle = styled.div`
  background-color: transparent;
  padding-top: 15px;
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
`;

const Ubicacion = styled.div`
  margin-left: 5vw;
  display: flex;
  justify-content: baseline;
  align-items: flex-end;
  opacity: 1;
  transition: all 0.3s linear;

  img {
    height: 23px;
    margin-right: 5px;
    z-index: -1;
  }
  p {
    font-size: 12px;
    font-weight: 600;
    color: #666666;
    margin: 0;
    z-index: -1;
  }
`;

const Tags = styled.div`
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  width: 100%;
  padding-top: 10px;
  padding-bottom: 5px;
`;

const Tag = styled.div`
  flex: 0 0 auto;
  padding: 3px 10px;
  border: 2px solid ${(props) => props.selected ? '#acacac' : '#dcdcdc'};
  border-radius: 10px;
  margin-right: 5px;
  background-color: ${(props) => props.selected ? '#acacac' : '#ffffff'};
  color: ${(props) => !props.selected ? '#acacac' : '#ffffff'};
  font-size: 14px;
  font-weight: 500;
`;

const Title = styled.p`
  font-size: 18px;
  font-weight: 500;
`;

const Promos = styled.div`
  margin: 20px 0;
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  overflow-x: scroll;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
`;

const Margen = styled.div`
  flex: 0 0 auto;
  width: 5vw;
`;

const Promo = styled.div`
  flex: 0 0 auto;
  display: flex;
  height: 100px;
  width: 270px;
  border: 1px solid #dcdcdc;
  border-radius: 15px;
  margin-right: 20px;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  background-color: #333;
  padding-left: 10px;
  padding-bottom: 10px;

  .title {
    font-size: 10px;
    color: #ffffff;
    line-height: 1;
  }
  .name {
    font-size: 18px;
    color: #ffffff;
    line-height: 1;
  }
  .price {
    font-size: 26px;
    color: #ffffff;
    line-height: 1;
  }

  .price:before {
    line-height: 1;
    content: '$';
    font-size: 12px;
  }
`;

const MasComprados = styled.div``;

const Productos = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
`;

const Producto = styled.div`
  width: 41vw;
  height: 160px;
  background-color: #ececec;
  margin-bottom: 20px;
  border: 1px solid #dcdcdc;
  border-radius: 15px;
  display: flex;
  flex-direction: row;
`;

const Imagen = styled.div`
  width: 50%;
  background-color: #ffffff;
  border-radius: 15px 0 0 15px;
`;

const Info = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: baseline;
  justify-content: space-evenly;

  span {
    margin-left: 10px;
  }

  div {
    display: flex;
    flex-direction: column;
  }

  .name {
    color: #333333;
    font-size: 12px;
    font-weight: 500;
  }
  .content {
    color: #8c8c8c;
    font-size: 10px;
    font-weight: 400;
  }
  .price {
    font-size: 14px;
    font-weight: 500;
  }
`;

const BuyButton = styled.div`
  margin-left: 10px;
  height: 25px;
  width: 50px;
  border-radius: 5px;
  background-color: #c8252c;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    background-color: #e15e64;
  }
  img {
    height: 60%;
  }
`;


class StoreScreen extends Component {
  state = {
    productos: [
      { id: 1, name: 'Black & White', content: '750 ml', type: 'Whisky', price: 160, },
      { id: 2, name: 'Presidente', content: '750 ml', type: 'Brandy', price: 130, },
      { id: 3, name: 'Fundador', content: '750 ml', type: 'Brandy', price: 210, },
      { id: 4, name: 'José Cuervo Especial', content: '990 ml', type: 'Tequila', price: 175, },
      { id: 5, name: 'Tecate lata 4pz', content: '473 ml', type: 'Cerveza', price: 72, },
      { id: 6, name: 'Heineken lata 4pz', content: '473 ml', type: 'Cerveza', price: 160, },
    ],
    paquetes: [
      { id: 1, name: 'Escocés', price: 220 },
      { id: 2, name: 'Rumbero', price: 220 },
      { id: 3, name: 'De Etiqueta', price: 310 },
      { id: 4, name: 'Imperial', price: 335 },
    ],
    tags: [
      { id: -1, name: 'Todos', selected: true },
      { id: 1, name: 'Cerveza', selected: false },
      { id: 2, name: 'Brandy', selected: false },
      { id: 5, name: 'Tequila', selected: false },
      { id: 3, name: 'Champagne', selected: false },
      { id: 4, name: 'Ginebra', selected: false },
    ],
    scrollY: 0,
  };

  isScrolling = () => {
    const { scrollY } = globalThis;
    this.setState({ scrollY });
  };

  componentDidMount = () => {
    window.addEventListener('scroll', this.isScrolling, false);
  };

  componentWillUnmount = () => {
    window.removeEventListener('scroll', this.isScrolling, false);
  }

  toggleTag = (idTag) => {
    const { tags } = this.state;
    const index = tags.findIndex(tag => tag.id === idTag);
    if (idTag === -1) {
      if (!tags[index].selected) {
        const deselectedTags = tags.map(tag => ({ ...tag, selected: false }));
        deselectedTags[index].selected = true;
        this.setState({ tags: deselectedTags });
      }
      return;
    }
    tags[index].selected = !tags[index].selected;
    const allIndex = tags.findIndex(tag => tag.id === -1);
    tags[allIndex].selected = false;

    if (tags.findIndex(tag => tag.selected) === -1) { // No encontró ninguna tag seleccionada
      tags[allIndex].selected = true;
    }

    this.setState({ tags });
  };

  render = () => {
    const { tags, productos, paquetes, scrollY } = this.state;
    return (
      <Container>
        <FixedHeader scrollY={scrollY}>
          <MainHeader />
          <SearchStyle className={scrollY > 50 ? 'base normal' : 'base top'}>
            <SearchBar
              width={'calc(100% - 10vw)'}
              placeholder="¿Qué estás buscando?"
            />
          </SearchStyle>
          <Ubicacion className={scrollY > 50 ? 'hide' : 'show'}>
            <img src={Location} alt=""/>
            <p>¿A dónde enviamos tu pedido?</p>
          </Ubicacion>
          <Tags>
            <Margen />
            {tags.map(tag => (
              <Tag
                key={tag.id}
                selected={tag.selected}
                onClick={() => this.toggleTag(tag.id)}
              >
                {tag.name}
              </Tag>
            ))}
            <Margen />
          </Tags>
        </FixedHeader>
        <Filler />
        <Promos>
          <Margen />
          {paquetes.map((paquete) => (
            <Promo key={paquete.id}>
              <span className="title">PAQUETE</span>
              <span className="name">{paquete.name}</span>
              <span className="price">{paquete.price}</span>
            </Promo>
          ))}
          <Margen />
        </Promos>
        <MasComprados>

        </MasComprados>
        <Spacer>
          <Title>Productos</Title>
          <Productos>
            {productos.map(producto => (
              <Producto key={producto.id}>
                <Imagen />
                <Info>
                  <span className="name">{producto.name}</span>
                  <div>
                    <span className="content">{producto.content}</span>
                    <span className="content">{producto.type}</span>
                  </div>
                  <span className="price">${producto.price}</span>
                  <BuyButton>
                    <img src={Cart} alt=""/>
                  </BuyButton>
                </Info>
              </Producto>
            ))}
          </Productos>
        </Spacer>
      </Container>
    )
  };
}

export default StoreScreen;
