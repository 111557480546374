import React, { Component } from 'react';
import styled from 'styled-components';

const Group = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 90vw;
  max-width: 300px;
  height: 50px;
  margin: 10px 0;
`;

const Radio = styled.div`
  font-weight: 600;
  font-size: 14px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  span {
    content: "";
    background-color: ${props => props.selection === props.value ? '#c8252c' : '#efefef'};
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 2px solid #dcdcdc;
    margin-right: 8px;
  }
`;

class RadioGroup extends Component {

  render = () => {
    const { setValue, options, value } = this.props;
    return (
      <Group>
        {options.map(option => (
          <Radio onClick={() => setValue(option.value)} selection={value} value={option.value}>
            <span/>
            {option.text}
          </Radio>
        ))}
      </Group>
    );
  }
}

export default RadioGroup;
