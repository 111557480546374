import React from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import App from './App';
import * as serviceWorker from './serviceWorker';

const Wraper = styled.div`
  font-family: 'Roboto', sans-serif;
  font-size: 12px;
  line-height: 1.5;
  width: 100vw;
  color: #222;
  button {
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    font-weight: 500;
  }
`;

ReactDOM.render(<Wraper><App /></Wraper>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
