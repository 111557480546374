import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';


const Header = styled.div`
  height: 10vh;
  width: 100%;
  background-color: #092644;
  background-image: linear-gradient(#092644, #0D3569);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: #ffffff;
  font-size: 16px;
  font-weight: 600;
`;

const Back = styled.span`
  padding-left: 15px;
  width: 60px;
  font-size: 25px;
`;

const Title = styled.span``;

const Info = styled.span`
  padding-right: 15px;
  width: 60px;
  font-size: 14px;
  text-align: end;
`;

const CustomHeader = (props) => (
  <Header>
    <Back onClick={props.back}><FontAwesomeIcon icon={faChevronLeft} /></Back>
    <Title>{props.title}</Title>
    <Info>{props.info}</Info>
  </Header>
);

export default CustomHeader;