import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

const Container = styled.div`
  background-color: #ffffff;
  border: 2px solid #dcdcdc;
  border-radius: 15px;
  width: ${(props) => props.width};
  height: 40px;
  display: flex;
  justify-content: base;
  align-items: center;
  z-index: 1;
  svg {
    padding-left: 15px;
    padding-right: 5px;
    font-size: 20px;
    color: #9c9c9c;
  }
  input::placeholder {
    color: #acacac;
    font-weight: 600;
  }
`;

const Input = styled.input`
  border: 0;
  font-size: 16px;
  color: #333333;
  width: 80%;
  height: 70%;
`;

const SearchBar = (props) => (
  <Container width={props.width}>
    <FontAwesomeIcon icon={faSearch} />
    <Input
      type="text"
      placeholder={props.placeholder}
    />
  </Container>
);

export default SearchBar;