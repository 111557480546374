import React, { Component } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-regular-svg-icons';
import { faFacebookF } from '@fortawesome/free-brands-svg-icons';

import HeaderLogo from './components/HeaderLogo';
import Button from './components/Button';
import BG2 from './bg-02.png';

const ThirdButton = styled.p`
  font-size: 12px;
  font-weight: 500;
`;

const A = styled.a`
  font-weight: 600;
  color: #c8252c; 
  text-decoration: none;
`;

const Container = styled.div`
  display: flex;
  height: 100vh;
  width: 100%;
  flex-direction: column;
  align-items: center;
  background-image: url(${BG2});
  background-size: cover;
`;

const Spacer = styled.div`
  width: 90vw;
  max-width: 300px;
  height: 90vh;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
`;

const MainButtons = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  flex-grow: 5;
  button {
    margin: 5px 0;
  }
  h1 {
    color: #333333;
    font-size: 24px;
    font-weight: 500;
  }
`;

const FootButtons = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;
`;

const BlankSpace = styled.div`
  display: flex;
  flex-grow: 1;
  contet: ".";
`;

const PrivacyTerms = styled.div`
  text-align: center;
  margin-bottom: 10px;
`;

class WelcomeScreen extends Component {
  render = () => {
    return (
      <Container>
        <HeaderLogo />
        <Spacer>
          <MainButtons>
            <h1>Te damos la bienvenida</h1>
            <div>
              <Link to="/registro">
                <Button
                  bgColor="#efefef"
                  bgHoverColor="#dcdcdc"
                  color="#222222"
                  border="2px solid #dcdcdc"
                  iconColor="#acacac"
                  icon
                >
                  <FontAwesomeIcon icon={faEnvelope} />
                  Regístrate con tu correo
                </Button>
              </Link>
              <Button
                bgColor="#092748"
                bgHoverColor="#13549b"
                color="#ffffff"
                iconColor="#ffffff"
                icon
              >
                <FontAwesomeIcon icon={faFacebookF} />
                Regístrate con Facebook
              </Button>
            </div>
          </MainButtons>
          <BlankSpace />
          <FootButtons>
            <Button
              bgColor="#c8252c"
              bgHoverColor="#e15e64"
              color="#ffffff"
            >Iniciar sesión</Button>
            <ThirdButton>Continuar como invitado</ThirdButton>
            <PrivacyTerms>
              Al registrarte aceptas las
              {' '}
              <A href="#">Condiciones del Servicio</A>
              {' '}
              y la
              {' '}
              <A href="#">Política de Privacidad</A>
              .
            </PrivacyTerms>
          </FootButtons>
          
        </Spacer>
      </Container>
    )
  };
}

export default WelcomeScreen;
