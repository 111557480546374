import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';

import Logo from '../logo.svg';
import Cart from '../cart.svg';


const Header = styled.div`
  height: 64px;
  width: 100vw;
  background-color: #092644;
  background-image: linear-gradient(#092644, #0D3569);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: #ffffff;
  font-size: 16px;
  font-weight: 600;
  z-index: 1;
`;

const Menu = styled.span`
  padding-left: 5vw;
  width: 60px;
  font-size: 25px;
`;

const Img = styled.img`
  width: 25%;
  max-width: 90px;
`;

const ShopCar = styled.span`
  padding-right: 5vw;
  width: 60px;
  text-align: end;
  img {
    width: 35px;
  }
`;

const CustomHeader = (props) => (
  <Header>
    <Menu onClick={props.toggleMenu}><FontAwesomeIcon icon={faBars} /></Menu>
    <Img src={Logo} alt="Logo de Cheerz" />
    <ShopCar><img src={Cart} alt="Carrito de compras"/></ShopCar>
  </Header>
);

export default CustomHeader;