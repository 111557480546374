import React from 'react';
import { HashRouter, Route } from 'react-router-dom';
import SignupScreen from './SignupScreen';
import WelcomeScreen from './WelcomeScreen';
import StoreScreen from './StoreScreen';

function App() {
  return (
    <div className="App">
      <HashRouter>
          <div>
            <Route
              exact
              path="/"
              render={() => (
                <WelcomeScreen />
              )}
            />
            <Route
              exact
              path="/registro"
              render={props => (
                <SignupScreen
                  {...props}
                />
              )}
            />
            <Route
              exact
              path="/tienda"
              render={props => (
                <StoreScreen
                  {...props}
                />
              )}
            />
          </div>
      </HashRouter>
    </div>
  );
}

export default App;
